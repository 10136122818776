<!--
 * @Author: lbh
 * @Date: 2022-06-21 15:19:50
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-08 14:36:24
 * @Description: file content
-->
<template>
  <div class="edit-swiper-box">
    <div class="cell-b">
      <div class="h3-box">
        <h3>輪播設置</h3>
        <el-button
          type="primary"
          size="mini"
          @click="addItem"
        >新增輪播</el-button>
      </div>

      <selfCell title="開啟輪播">
        <el-radio
          v-model="configs.autoplay"
          @change="setValue('autoplay')"
          :label="false"
        >否</el-radio>
        <el-radio
          v-model="configs.autoplay"
          @change="setValue('autoplay')"
          :label="true"
        >是</el-radio>
      </selfCell>
      <selfCell title="設置導航欄顏色">
        <el-color-picker
          v-model="configs.tabColor"
          @change="setValue('tabColor')"
        />
      </selfCell>
      <selfCell
        title="輪播間隔時長"
        v-if="configs.autoplay"
      >
        <el-radio
          v-model="configs.interval"
          :label="1"
          @change="setValue('interval')"
        >1S</el-radio>
        <el-radio
          v-model="configs.interval"
          @change="setValue('interval')"
          :label="2"
        >2S</el-radio>
        <el-radio
          v-model="configs.interval"
          @change="setValue('interval')"
          :label="3"
        >3S</el-radio>
      </selfCell>
      <selfCell title="開啟循環">
        <el-radio
          v-model="configs.loop"
          @change="setValue('loop')"
          :label="false"
        >否</el-radio>
        <el-radio
          v-model="configs.loop"
          @change="setValue('loop')"
          :label="true"
        >是</el-radio>
      </selfCell>
    </div>
    <div class="cell-b">
      <div class="h3-box">
        <h3>項設置</h3>
        <el-button
          size="mini"
          @click="removeItem"
        >刪除此項</el-button>
      </div>
      <selfCell title="點擊跳轉">
        <selfPagePicker
          v-model="configs.items[index].go"
          @onChange="onChange"
        />
      </selfCell>
      <selfCell title="項類型">
        <el-radio
          v-model="configs.items[index].type"
          @change="setValue('items')"
          label="1"
        >圖片</el-radio>
        <el-radio
          v-model="configs.items[index].type"
          @change="setValue('items')"
          label="2"
        >自定義內容</el-radio>
      </selfCell>
      <selfCell title="選擇移動端圖片">
        <selfUpload
          v-model="configs.items[index].phoneImg"
          type="default"
          @change="setValue('items')"
        />
      </selfCell>
      <selfCell title="選擇填充圖片">
        <selfUpload
          v-model="configs.items[index].bgImg"
          type="default"
          @change="setValue('items')"
        />
      </selfCell>
      <selfCell title="輸入填充圖片描述">
        <el-input
          v-model="configs.items[index].bgAlt"
          type="textarea"
          @change="setValue('items')"
        />
      </selfCell>
      <div v-if="configs.items[index].type == 2">
        <div class="cell-b">
          <selfCell
            v-for="lItem in language"
            :key="lItem.code"
            :title="`標題-${lItem.name}`"
          >
            <el-input
              v-model="configs.items[index][`${lItem.code == 'HK' ? '' : lItem.code}title`]"
              placeholder="請輸入標題"
              type="textarea"
              rows="3"
              @input="setValue('items')"
            ></el-input>
          </selfCell>
        </div>
        <div class="cell-b">
          <selfCell
            v-for="lItem in language"
            :key="lItem.code"
            :title="`描述-${lItem.name}`"
          >
            <el-input
              v-model="configs.items[index][`${lItem.code == 'HK' ? '' : lItem.code}desc`]"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 10}"
              placeholder="請輸入標題"
              @input="setValue('items')"
            ></el-input>
          </selfCell>
        </div>
        <selfCell title="選擇圖片">
          <selfUpload
            v-model="configs.items[index].img"
            type="default"
            @change="setValue('items')"
          />
        </selfCell>
        <selfCell title="輸入圖片描述">
          <el-input
            type="textarea"
            v-model="configs.items[index].alt"
            @change="setValue('items')"
          ></el-input>
        </selfCell>
        <h3>按鈕設置</h3>
        <div
          v-for="(btn,b_index) in configs.items[index].btns"
          :key="b_index"
        >
          <div class="cell-b">

            <selfCell
              v-for="lItem in language"
              :key="lItem.code"
              :title="`按鈕-${lItem.name}-文字`"
            >
              <el-input
                v-model="btn[`${lItem.code == 'HK' ? '' : lItem.code}title`]"
                placeholder="請輸入標題"
                @input="setValue('items')"
                type="textarea"
                rows="3"
              ></el-input>
            </selfCell>
          </div>
          <selfCell title="按鈕-跳轉">
            <selfPagePicker
              v-model="btn.go"
              @onChange="onChange"
            />
          </selfCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getByName } from '@/js/utils/default_config';
export default {
  name: "edit-swiper",

  props: {
    type: {
      default () {
        return "item";
      }
    },
    configs: {
      default () {
        return {
          bgImg: '',
          autoplay: false, // 是否自動輪播
          interval: 3, // 時間
          loop: false, // 是否 循環
          type: '1', // 1 是圖片 , 2 是 內容
          img: '',
          btns: [],
          title: '',
          desc: '',
        }
      }
    },
    index: {
      default () {
        return 0;
      }
    }
  },
  data () {
    return {
      language: []
    }
  },
  created () {
    let language = this.$storage.get('language')
    this.language = language;
  },
  methods: {
    onChange () {
      this.setValue('items');
    },
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
    addItem () {
      let swiper = getByName('swiper')
      let item = swiper.configs.items[1]
      this.$emit('addItem', { index: this.index, item: item, key: 'items' })
    },
    removeItem () {
      this.$emit('delItem', { index: this.index, key: 'items' })
    }
  }
}
</script>

<style lang="less" scoped>
.h3-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>